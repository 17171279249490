// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports
// import "bootstrap";

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import Swal from 'sweetalert2';
import Chart from 'chart.js';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import 'mapbox-gl/dist/mapbox-gl.css';

// Internal imports, e.g:
import { changeNavbar } from '../components/navbar';
import { showImage } from '../components/form-image';
import { userUpdateForm, addSelectButtonListeners, clickPreviousSelected } from '../components/form-user';
import { removeInvalidAlerts, addEventBlurs, runInitialBlurs } from '../components/form-new';
import { updateTicketForm, addTicketFormListeners } from '../components/form-ticket';
import { maxSizeAlert } from '../components/trix';
import { deleteObject, deleteTicket } from '../components/alerts';
import { updateHiddenSearch } from '../components/search';
import { showButtonToTop } from '../components/to-top';
import { printPage, pdfPage } from '../components/extraction';
import { Toast } from '../plugins/sweetalert';
import { initChartJs } from '../plugins/chart';
import { initMapbox } from '../plugins/mapbox';
import { loadDatePickers, overrideParamValues } from '../plugins/flatpickr';

// Notification for test purposes
// UIkit.notification('Hello world.');

window.Toast = Toast;
window.onscroll = function() {
  changeNavbar(0); // Navbar will change once it moves from 0px
  showButtonToTop(500); // To top button will appear once scroll is greater than 500px
};

document.addEventListener('turbolinks:load', () => {
  // Initiate AOS library
  AOS.init({ mirror: true });
  // Loads the Icon plugin
  UIkit.use(Icons);
  // Define chart kick defaults
  initChartJs();
  // Show ticket modal if relevant
  const tmodal_button = document.querySelector("#event-ticket-button")
  const tmodal = document.querySelector("#event-tickets")
  if (tmodal_button !== null && tmodal_button.classList.contains('show-modal')) { UIkit.modal(tmodal).show() }
  // Apply behaviour to forms
  showImage(); // Display images on forms
  userUpdateForm(); // Gets correct tab of user edit form
  addSelectButtonListeners(); // Add listeners to user select boxes
  clickPreviousSelected(); // Click boxes previously selected
  runInitialBlurs(); // Ensure disabled are always loaded
  addEventBlurs(); // Add listeners to disable fields
  removeInvalidAlerts(); // Add listeners to remove errors on input change
  updateTicketForm(); // Set initial ticket form info
  addTicketFormListeners(); // Add listeners to update ticket info as inputs change
  // Limit attachment max size for active-text
  window.addEventListener("trix-file-accept", (event) => { maxSizeAlert(event, 4) });
  // Add flatpickr to relevant fields
  loadDatePickers();
  // Get current search to filter modal hidden field
  $("#filter-modal").on('shown', () => updateHiddenSearch());
  // Get print button to print
  $("#print-button").on('click', () => printPage());
  // Initiate mapbox
  const mapElement = document.getElementById('map');
  initMapbox(mapElement);
  // Set alert for permanent object delete
  const deleteBtn = document.getElementById("dummy-delete-object");
  if (deleteBtn !== null) { deleteBtn.addEventListener("click", (event) => { deleteObject(event) }); };
  // Set alert for permanent ticket delete in events
  const deleteTicketBtn = document.querySelectorAll(".dummy-delete-ticket");
  if (deleteTicketBtn !== null) {
    deleteTicketBtn.forEach((btn) => {
      btn.addEventListener("click", (event) => { deleteTicket(event) });
    })
  };
});

require("trix")
require("@rails/actiontext")
