const updateTicketForm = () => {
  const form = document.querySelector("#new_ticket");

  if (form !== null) {
    let memberSlots = 0;
    let memberPrice = 0;
    let memberMax = null;
    let escortSlots = 0;
    let escortPrice = 0;
    let escortMax = null;
    let visitorSlots = 0;
    let visitorPrice = 0;
    let visitorMax = null;

    const members = form.querySelector("#members")
    if (members !== null) {
      memberSlots = parseInt(members.value, 10) || 0;
      memberPrice = parseFloat(members.dataset.price);
      memberMax = parseInt(members.dataset.max, 10);

      if (memberSlots > memberMax) {
        memberSlots = memberMax
        members.value = memberMax
      } else if (memberSlots < 0) {
        memberSlots = 0
        members.value = 0
      }
    }

    const escorts = form.querySelector("#escorts")
    if (escorts !== null) {
      escortSlots = parseInt(escorts.value, 10) || 0;
      escortPrice = parseFloat(escorts.dataset.price);
      escortMax = parseInt(escorts.dataset.max, 10);

      if (escortSlots > escortMax) {
        escortSlots = escortMax
        escorts.value = escortMax
      } else if (escortSlots < 0) {
        escortSlots = 0
        escorts.value = 0
      }
    }

    const visitors = form.querySelector("#visitors")
    if (visitors !== null) {
      visitorSlots = parseInt(visitors.value, 10) || 0;
      visitorPrice = parseFloat(visitors.dataset.price);
      visitorMax = parseInt(visitors.dataset.max, 10);

      if (visitorSlots > visitorMax) {
        visitorSlots = visitorMax
        visitors.value = visitorMax
      } else if (visitorSlots < 0) {
        visitorSlots = 0
        visitors.value = 0
      }
    }

    const slots = memberSlots + escortSlots + visitorSlots
    const vat = form.dataset.vat
    const base_vat = 1 + vat / 100
    const base_price = memberSlots * memberPrice + escortSlots * escortPrice + visitorSlots * visitorPrice
    const price = base_vat * base_price

    let lugar = 'lugares'
    if (slots === 1) { lugar = 'lugar' }

    const btn = form.querySelector("#submit-btn")
    if (btn !== null) {
      btn.value = `Registar (${price.toFixed(2)} € - ${slots} ${lugar})`

      if (price >= 0 && slots > 0) {
        btn.disabled = false;
      } else {
        btn.disabled = true;
      }
    }

    const maxSlotsField = form.querySelector("#max-tickets")
    if (maxSlotsField !== null) {
      const maxSlots = parseInt(maxSlotsField.dataset.max, 10)

      if (slots > maxSlots) {
        maxSlotsField.classList.add("uk-text-danger");
        if (btn !== null) { btn.disabled = true; }
      } else {
        maxSlotsField.classList.remove("uk-text-danger");
      }
    }
  }
}

const addTicketFormListeners = () => {
  const ticketFields = document.querySelectorAll(".ticket-field");
  if (ticketFields !== null) {
    ticketFields.forEach((tf) => {
      tf.addEventListener("keyup", (event) => updateTicketForm());
      tf.addEventListener("touchend", (event) => updateTicketForm());
    })
  }
}

export { updateTicketForm, addTicketFormListeners }
