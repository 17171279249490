import Chart from 'chart.js';

const initChartJs = () => {
  Chart.defaults.global.defaultFontColor = 'rgba(180, 185, 190, 1)';
  Chart.defaults.global.defaultFontFamily = "Roboto", "Helvetica", "sans-serif";
  Chart.defaults.global.defaultFontSize = 11;
  Chart.defaults.global.elements.point.pointStyle = 'rectRounded';
}

export { initChartJs };
