const addSelectButtonListeners = () => {
  const boxes = document.querySelectorAll(".select-boxes .form-check-label")
  if (boxes !== null) {
    boxes.forEach((box) => {
      box.addEventListener("click", (event) => { event.currentTarget.classList.toggle("active") })
    })
  }
}

const clickPreviousSelected = () => {
  const boxes = document.querySelectorAll(".select-boxes .tba")
  if (boxes !== null) { boxes.forEach((box) => { box.click(); }) }
}

const userUpdateForm = () => {
  const form = document.querySelector(".edit-user-form")
  if (form !== null) {
    const firstInvalid = form.querySelector(".is-invalid");
    if (firstInvalid !== null) {
      const parentLi = firstInvalid.closest("li");
      const tabId = parentLi.dataset.id;
      const navPill = form.querySelector(`#sn-${tabId}`);
      navPill.click();
    }
  }
}

export { userUpdateForm, addSelectButtonListeners, clickPreviousSelected }
