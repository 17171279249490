const readURL = (input, type) => {
  console.log(input)
  if (input.files && input.files[0]) {
    const reader = new FileReader();
    reader.onload = (e) => { $(`#${type}_prev`).attr('src', e.target.result); }
    reader.readAsDataURL(input.files[0]);
  }
}

const showImage = () => {
  $("#banner-upload").change(function(){
    $('#banner_prev').removeClass('form-hidden-image');
    readURL(this, 'banner');
  });

  $("#logo-upload").change(function(){
    $('#logo_prev').removeClass('form-hidden-image');
    readURL(this, 'logo');
  });
}

export { showImage }

