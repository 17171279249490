const runRemoval = (item) => {
  item.classList.remove("is-invalid");
  const parent = item.parentElement
  parent.classList.remove("form-group-invalid");
  const message = parent.querySelector(".invalid-feedback")
  if (message !== null) { message.remove(); }
}

const runRemovalTime = (type) => {
  const klass = `.form-group.datetime.event_${type}_time`
  const parent = document.querySelector(klass)
  if (parent !== null) {
    parent.classList.remove("form-group-invalid");
    const message = parent.querySelector(".invalid-feedback")
    if (message !== null) { message.remove(); }
    const items = parent.querySelectorAll(".is-invalid");
    items.forEach((item) => { item.classList.remove("is-invalid") });
  }
}

const removeInvalidAlerts = () => {
  const formErrors = document.querySelectorAll('.is-invalid');
  if (formErrors !== null) {
    formErrors.forEach((error) => {
      error.addEventListener("change", (event) => { runRemoval(error); });
    })
  }
}

const eventPhysical = () => {
  const physicalBtn = document.querySelector('#physical-btn');
  const physicalTrues = document.querySelectorAll('.physical-true');
  const physicalFalses = document.querySelectorAll('.physical-false');

  if (physicalBtn !== null) {
    const status = physicalBtn.value
    if (status === "true") {
      physicalTrues.forEach((btn) => { btn.disabled = false; })
      physicalFalses.forEach((btn) => {
        btn.disabled = true;
        btn.value = null;
      })
    } else if (status === "false") {
      physicalTrues.forEach((btn) => {
        btn.disabled = true;
        btn.value = null;
      })
      physicalFalses.forEach((btn) => { btn.disabled = false })
    }
  }
}

const eventTickets = (klass) => {
  const ticketField = document.querySelector(`#ticket-${klass}`);
  const relativeField = document.querySelectorAll(`.ticket-${klass}-blur`);

  if (ticketField !== null) {
    const val = ticketField.value
    if (val === "" || val === null) {
      relativeField.forEach((btn) => {
        btn.disabled = true;
        btn.value = null;
      })
    } else {
      relativeField.forEach((btn) => {
        btn.disabled = false;
      })
    }
  }
}

const addEventBlurs = () => {
  const physicalBtn = document.querySelector('#physical-btn');
  if (physicalBtn !== null) { physicalBtn.addEventListener('change', (event) => { eventPhysical() } ) }

  const ticketMember = document.querySelector('#ticket-member');
  if (ticketMember !== null) { ticketMember.addEventListener('change', (event) => { eventTickets("member") } ) }

  const ticketEscort = document.querySelector('#ticket-escort');
  if (ticketEscort !== null) { ticketEscort.addEventListener('change', (event) => { eventTickets("escort") } ) }

  const ticketVisitor = document.querySelector('#ticket-visitor');
  if (ticketVisitor !== null) { ticketVisitor.addEventListener('change', (event) => { eventTickets("visitor") } ) }
}

const runInitialBlurs = () => {
  eventPhysical();
  eventTickets("member");
  eventTickets("escort");
  eventTickets("visitor");
}

export { runRemovalTime, runRemoval,
         removeInvalidAlerts,
         addEventBlurs, runInitialBlurs };
