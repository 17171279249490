import Swal from 'sweetalert2';

const maxSizeAlert = (event, size) => {
  const maxFileSize = 1024 * 1024 * size;
  if (event.file.size > maxFileSize) {
    event.preventDefault();
    const convSize = (event.file.size / (1024 * 1024)).toFixed(2)
    Swal.fire({
      title: `<span class="uk-text-danger">Ficheiro Não Importado</span>`,
      html: `<p class="uk-text-light uk-margin-small-top" style="line-height: 1.6; color: #888; font-size: 1rem">O ficheiro carregado com ${convSize}MB excede o limite de tamanho máximo de ${size}MB.<br><br>Experimente fazer upload noutro formato ou colocar num serviço externo de hosting e inserir o respectivo link no texto.</p>`,
      icon: 'warning',
      showCancelButton: false,
      confirmButtonText: 'Voltar',
      confirmButtonColor: '#a3a3a3',
    })
  }
}

export { maxSizeAlert };
