import Swal from 'sweetalert2';

const deleteObject = (event) => {
  event.preventDefault();
  const realBtn = document.getElementById("delete-object");
  const type = realBtn.dataset.type;
  const type_pt = realBtn.dataset.typept;

  let message = ""
  if (type === "event") {
    message = `<p class="uk-text-light uk-margin-small-top" style="line-height: 1.6; color: #888; font-size: 1rem">Tem a certeza que pretendes eliminar definitivamente este evento?<br><br>Todos os bilhetes emitidos serão igualmente destruídos, de forma permanente.<br><br>Esta operação é irreversível, em alternativa sugere-se a alteração do estado para removido.</p>`
  } else {
    message = `<p class="uk-text-light uk-margin-small-top" style="line-height: 1.6; color: #888; font-size: 1rem">Tem a certeza que pretendes eliminar definitivamente este ${type_pt}?<br><br>Esta operação é irreversível, em alternativa sugere-se a alteração do estado para removido.</p>`
  }

  Swal.fire({
    title: `<span class="uk-text-danger">Eliminar</span>`,
    html: `${message}`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Eliminar',
    confirmButtonColor: '#E68282',
    cancelButtonText: 'Cancelar',
    cancelButtonColor: '#a3a3a3'
  }).then((result) => {
    if (result.value) {
      realBtn.click();
    }
  })
}

const deleteTicket = (event) => {
  event.preventDefault();

  const id = event.target.dataset.tid;
  const realBtn = document.getElementById(`delete-ticket-${id}`);
  const message = `<p class="uk-text-light uk-margin-small-top" style="line-height: 1.6; color: #888; font-size: 1rem">Tem a certeza que pretendes eliminar definitivamente este bilhete?<br><br>Esta operação é irreversível, em alternativa sugere-se a alteração do estado para cancelado.</p>`

  Swal.fire({
    title: `<span class="uk-text-danger">Eliminar</span>`,
    html: `${message}`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Eliminar',
    confirmButtonColor: '#E68282',
    cancelButtonText: 'Cancelar',
    cancelButtonColor: '#a3a3a3'
  }).then((result) => {
    if (result.value) {
      realBtn.click();
    }
  })
}

export { deleteObject, deleteTicket };
