const changeNavbar = (height) => {
  const navbarContainer = document.getElementById("navbar-container");
  const navbarContent = document.getElementById("navbar");
  // const bigLogo = document.getElementById("aacl-logo-lg");
  // const smallLogo = document.getElementById("aacl-logo-sm");

  if (navbarContainer !== null && navbarContent !== null) {
    if (document.body.scrollTop > height || document.documentElement.scrollTop > height) {
      navbarContent.classList.remove('uk-navbar-transparent');
      navbarContainer.classList.add('uk-background-default');
      navbarContainer.classList.add('navbar-moved');
      // bigLogo.style.height = "80px"
    } else {
      navbarContent.classList.add('uk-navbar-transparent');
      navbarContainer.classList.remove('uk-background-default');
      navbarContainer.classList.remove('navbar-moved');
      // bigLogo.style.height = "100px"
    }
  }
}

export { changeNavbar };

