import flatpickr from "flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js"
flatpickr.localize(Portuguese);

import { runRemovalTime } from '../components/form-new';

const loadDatePickers = () => {
  flatpickr(".date-picker", {
    altInput: true,
    time_24hr: true,
    altFormat: "j F Y",
    dateFormat: "Y-m-d",
    minDate: "2020-01-01",
    maxDate: "3000-12-31"
  });

  flatpickr(".date-picker-past", {
    altInput: true,
    time_24hr: true,
    altFormat: "j F Y",
    dateFormat: "Y-m-d",
    minDate: "2020-01-01",
    maxDate: "today"
  });

  flatpickr(".date-picker-future", {
    altInput: true,
    time_24hr: true,
    altFormat: "j F Y",
    dateFormat: "Y-m-d",
    minDate: "today",
    maxDate: "3000-12-31"
  });

  flatpickr(".time-picker-start", {
    altInput: true,
    time_24hr: true,
    enableTime: true,
    altFormat: "j F Y - H:i",
    dateFormat: "Y-m-d H:i",
    minDate: "2020-01-01", // new Date().fp_incr(1)
    maxDate: "3000-12-31",
    onClose: function(selectedDates, dateStr, instance){ runRemovalTime("start") }
  });

  flatpickr(".time-picker-end", {
    altInput: true,
    time_24hr: true,
    enableTime: true,
    altFormat: "j F Y - H:i",
    dateFormat: "Y-m-d H:i",
    minDate: "2020-01-01", // new Date().fp_incr(1)
    maxDate: "3000-12-31",
    onClose: function(selectedDates, dateStr, instance){ runRemovalTime("end") }
  });
}

export { loadDatePickers };
