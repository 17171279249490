const showButtonToTop = (height) => {
  const btnToTop = document.getElementById("to-top");
  if (btnToTop !== null) {
    if (document.body.scrollTop > height || document.documentElement.scrollTop > height) {
      btnToTop.style.display = "flex";
    } else {
      btnToTop.style.display = "none";
    }
  }
}

export { showButtonToTop };
